:root {
  --primaryColor: #27C35D;
  --primaryColorHover: #1EAD50;
  --backgroundColor: #e0e0e0;
  --whiteColor: #ffffff;
  --linkColor: #868894;
  --secondaryColor: #393C50;
}

/* goLiza new */

* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-decoration: none;
  box-sizing: border-box;
}

h1 {
  font-size: 42px;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  background-color: var(--backgroundColor);
  width: 100%;
  min-height: 100vh;
  /* height: 100vh; */
  /* font-family: 'Muli', sans-serif; */
  font-family: 'Mulish', sans-serif !important;
  overflow-x: hidden;
}

.customSpan {
  font-size: 14px;
}

button.button-schedule {
  background-color: var(--primaryColor);
  border: none;
  color: #ffffff;
  font-size: 16px;
  height: 40px;
  width: 30%;
  margin: 40px auto;
}

button.button-schedule:hover {
  cursor: pointer;
  background-color: var(--primaryColorHover);
}

#header {
  width: 100%;
  height: 520px;
  background-image: url('../assets/img/header.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

#header-menu {
  transition: 1s;
}

#header-menu.sticky {
  background-color: #fff;
  position: fixed;
  height: 70px;
  top: 0;
  width: 100%;
  color: #000;
  transition: 1s;
  z-index: 999;
}

#header-menu.sticky a {
  color: var(--secondaryColor);
}

#header-menu.sticky a:hover {
  color: var(--primaryColor);
}

#header-menu img {
  height: 30px;
  width: auto;
  /* background-image: url('../assets/img/logo-landing-nav.png'); */
  z-index: 999;
}

#header-menu.sticky img {
  height: 30px;
  width: auto;
  /* background-image: url('../assets/img/logo-navbar.png'); */
}

#menu-device {
  display: none;
  height: auto;
  position: absolute;
  top: 70px;
  width: 100%;
  background-color: #ffffff;
  z-index: 9999;
}

#menu-device>ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 1rem;
}

#menu-device>ul>li a:hover {
  color: var(--primaryColor);
  transition: 300ms;
}

button#btn-menu-liza {
  background: transparent;
  border: none;
  /* color: #ffffff; */
}

/* button#btn-menu-liza.active {
  color: var(--secondaryColor)
} */



#menu-device .active {
  display: block;
  position: fixed;
  width: 100%;
  height: auto;
  transition-delay: 1s;
  /* transition-property: display; */
}


.header-content {
  width: 90%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

img.logo-id {
  background-image: url('../assets/img/logo-landing-nav.png');
}

.header-description {
  width: 90%;
  height: 520px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: -70px auto;
}

.header-description h2 {
  font-size: 32px;
  color: #ffffff;
  font-weight: 100;
  width: 50%;
}

.header-description span {
  color: #ffffff;
  font-size: 2.2rem;
  font-weight: 100;
}

.header-info {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.header-info h1 {
  color: #ffffff;
  font-size: 5.4rem;
  font-weight: 500;
}

.header-info>button {
  background-color: var(--primaryColor);
  border: none;
  color: #ffffff;
  height: 40px;
  width: 25%;
}

.header-info>button:hover {
  background-color: var(--primaryColorHover);
  transition: 300ms;
  cursor: pointer;
}

.menu-header {
  z-index: 999;
}

.menu-header>ul {
  display: flex;
  flex-direction: row;
  gap: 28px;
}

.menu-header>ul>li>a {
  color: rgba(255, 255, 255, 0.80);
}

.menu-header>ul>li>a:hover {
  color: var(--primaryColor);
  transition: 300ms;
}

a.menu-item {
  padding-top: 8px;
  font-size: 16px;
}

a.login {
  border: 1.5px solid var(--primaryColor);
  color: var(--primaryColor) !important;
  background: transparent;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}

a.login:hover {
  background-color: var(--primaryColorHover);
  color: #fff !important;
  transition: 300ms;
  cursor: pointer;
}


.steps {
  width: 100%;
  height: 40vh;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.step-button {
  padding: 2rem;
  width: 300px;
  font-size: 1.7rem;
  color: #ffffff;
  background: var(--primaryColor);
  border: none;
}

.phrase {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--secondaryColor);
}

.content-phrase {
  height: 100%;
  width: 90%;

  & span {
    font-weight: bold;
  }

  & p {
    font-size: 2rem;
  }
}

.content-phrase h3 {
  /* font-size: 36px; */
  font-weight: 500;
  /* color: var(--secondaryColor); */
  color: #ffffff;
}

.content-phrase p {
  font-weight: 100;
  color: #ffffff;
}

.line-pharse {
  width: 30%;
  height: 5px;
  background: #ffffff;
  margin-top: 1rem;
  margin-bottom: 3rem;
}


.products {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

#products {
  padding-top: 100px;
}

.product-liza-register {
  width: 100%;
  height: 250px;
  background: #49C172;
}

.product-liza-register button.button-product:hover {
  cursor: pointer;
}

.product-liza-register button.button-product:focus {
  color: #ffffff;
}

.product-liza-register span.material-symbols-outlined:focus {
  color: #ffffff;
}


.product-liza-valid button.button-product:hover {
  cursor: pointer;
}

.product-liza-valid button.button-product:focus {
  color: #ffffff;
}

.product-liza-valid span.material-symbols-outlined:focus {
  color: #ffffff;
}


.product-liza-fof button.button-product:hover {
  cursor: pointer;
}

.product-liza-fof button.button-product:focus {
  color: #ffffff;
}

.product-liza-fof span.material-symbols-outlined:focus {
  color: #ffffff;
}


.product-liza-assembly button.button-product:hover {
  cursor: pointer;
}

.product-liza-assembly button.button-product:focus {
  color: #ffffff;
}

.product-liza-assembly span.material-symbols-outlined:focus {
  color: #ffffff;
}


.product-liza-valid {
  width: 100%;
  height: 250px;
  background: #27C35D;
}

.product-liza-fof {
  width: 100%;
  height: 250px;
  background: #41B35B;
}

.product-liza-assembly {
  width: 100%;
  height: 250px;
  background: #43B85E;
}

button.button-product {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  gap: 16px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  color: var(--secondaryColor);
}

button.button-product span.material-symbols-outlined {
  font-size: 48px;
  color: var(--secondaryColor);
}

.material-symbols-outlined {
  font-size: 48px !important;
}

/* button.button-product p {
  font-size: 18px;
  font-weight: 600;
  color: var(--secondaryColor);
} */



.area-products {
  width: 100%;
}

.content-product-button {
  width: 90%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

button.tab-button {
  margin-right: 16px;
  border: none;
  color: var(--linkColor);
  font-size: 18px;
  background: transparent;
}

button.tab-button:hover {
  cursor: pointer;
}

button.tab-button:active {
  color: var(--primaryColor);
}

button.tab-button:focus {
  color: var(--primaryColor);
  /* border-bottom: 5px solid var(--primaryColor); */
}

.tab-line {
  width: 100%;
  height: 5px;
  background: #dddddd;
}

.area-product-content {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}

.area-product-content h1 {
  color: var(--secondaryColor);
  margin-bottom: 16px;
}

.area-product-content p {
  color: var(--linkColor);
  margin-bottom: 16px;
}

.area-product-content .line-text {
  background: var(--secondaryColor);
  width: 100%;
  height: 5px;
}

.area-product-video {
  margin: 40px auto;
  max-width: 100%;
}

#kit-register {
  margin-top: 40px;
}

.area-liza-fof {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 40px;
  margin-bottom: 40px;
  align-items: center;
}

.area-left-content h1 {
  margin-bottom: 20px;
}

.area-left-content ul>li {
  list-style-type: square;
  color: var(--linkColor);
}

.area-left-content ul {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.area-right-content {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  height: 100%;
  ;
}

.timeline {
  width: 2px;
  height: 100%;
  background: var(--primaryColor);
}

.fof-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.fof-item {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.fof-item span {
  color: var(--linkColor)
}

.fof-item h3 {
  color: var(--primaryColor);
}

/* goLiza new */

.responsive {
  max-width: 100%;
}

.bold {
  font-weight: bold;
}


a:link {
  color: var(--secondaryColor);
}

a:visited {
  color: var(--secondaryColor);
}

a:active {
  color: var(--primaryColor);
}

.buttonLogin:visited {
  color: var(--primaryColor) !important;
}

.logoLiza img {
  width: 100%;
  /* max-width: 230px; */
  max-width: 112px;
  height: auto;
}

.logoLizaPitch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.content {
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  background-color: var(--whiteColor);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  z-index: 9;
}

.contentHiddenMenu {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  background-color: var(--whiteColor);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
}

.logoLizaHiddenMenu {
  margin-left: 10%;
}

.content-pitch {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  background-color: var(--whiteColor);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 999;
}

#menuLizaDeviceItem {
  position: fixed;
  top: 100px;
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  background: var(--whiteColor);
  padding-left: 1rem;
  gap: 5px;
  z-index: 9;
}

.menuLiza {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.lizaItemMenu {
  margin-right: 2rem;
  font-size: 16px;
}

.menuLiza>li> {
  color: var(--linkColor);
  font-weight: bold;
}

.menuLiza>li>a:hover {
  color: var(--primaryColor);
}

.buttonLogin {
  border: 2px solid var(--primaryColor);
  padding: 0.6rem 1rem;
  border-radius: 4px;
  color: var(--primaryColor);
  font-weight: bold;
  background: transparent;
  cursor: pointer;
}

.buttonLogin:hover {
  color: var(--whiteColor) !important;
  background-color: var(--primaryColor);
  transition: 0.5s;
}

.headerLiza {
  margin-top: 100px;
  width: 100%;
  height: 90vh;
  background-image: url('../assets/img/header-background.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--whiteColor);
}



.headerLizaWL {
  width: 100%;
  height: 65vh;
  background-image: url('../assets/img/header-background.png');
  background-size: cover;
  color: var(--whiteColor);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.headerLizaWL>h1 {
  margin-left: 5%;
}

.headerLizaWL>span {
  margin-left: 5%;
  margin-top: 0.3%;
}

.headerLizaWL>a {
  margin-left: 5%;
  margin-top: 1%;
}

.headerLiza h2 {
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
  width: 90%;
}

.headerLiza span {
  width: 90%;
  text-align: center;
  margin-bottom: 15px;
}

a.buttonLiza {
  margin-top: 3rem;
  width: 200px;
  height: auto;
  background-color: var(--primaryColor);
  border-radius: 3px;
  padding: 0.8rem;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
}

a.buttonLizaWL {
  width: auto;
  height: 40px;
  background-color: var(--primaryColor);
  border-radius: 3px;
  padding: 1rem;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
}

a.buttonLiza:hover {
  background-color: var(--primaryColorHover);
  transition: 0.5s;
}

a.buttonLizaWL:hover {
  background-color: var(--primaryColorHover);
  transition: 0.5s;
}

#soluctions {
  padding: 3rem;
  width: 100%;
  height: auto;
}

#customers {
  padding: 8rem;
  padding-top: 18rem;
  padding-bottom: 18rem;
  width: 100%;
  height: auto;
  background-color: #e0e0e0;
}

#midia {
  padding: 8rem;
  width: 100%;
  height: auto;
}

.bodyMidia {
  padding: 3rem;
  gap: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.cardMedia {
  background-color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.bodyCustomer {
  padding: 6rem;
  gap: 8rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.item-customer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: var(--linkColor)
}

#partners {
  padding: 8rem;
  padding-top: 18rem;
  padding-bottom: 18rem;
  width: 100%;
  height: auto;
  background-color: white;
}

.bodyPartners {
  padding: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
}

.item-partner {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: var(--linkColor);
  text-align: center;
}

.contentButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.buttonSolutionOulined:first-child {
  border-radius: 30px 0 0 30px;
}

.buttonSolutionOulined.active {
  background-color: var(--primaryColor);
  color: var(--whiteColor);
}

.buttonSolutionOulined:last-child {
  border-radius: 0 30px 30px 0;
}

.buttonSolutionOulined {
  border: 2px solid var(--primaryColor);
  padding: 0.5rem;
  color: var(--primaryColor);
  font-weight: bold;
}

.buttonSolutionOulined:hover {
  background: var(--primaryColor);
  color: var(--whiteColor)
}

.contentSoluctionsInfo {
  margin-top: 6rem;
  display: flex;
  flex-direction: row;
  align-items: space-between;
}

.subTitle {
  font-size: 30px;
  font-weight: bold;
}

.title {
  font-weight: bold;
  font-size: 38px;
  display: flex;
  text-align: center;
  flex-direction: column;
  color: var(--secondaryColor);
  margin-top: -15px;
}

.titlePrimary {
  font-weight: bold;
  font-size: 38px;
  display: flex;
  text-align: center;
  flex-direction: column;
  color: var(--primaryColorHover);
  margin-top: -15px;
}

.contentButtonsSoluctions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentButtonsSoluctions>button {
  width: 50%;
  height: 40px;
  border-radius: 5px;
  border: none;
  margin-top: 5px;
  font-size: 16px;
  background-color: transparent;
  color: var(--secondaryColor)
}

.leftButtonsInfoLizaRegister>button>span {
  margin-left: 10px;
}

.leftButtonsInfoLizaRegister button:hover {
  border: 2px solid var(--primaryColor);
  color: var(--primaryColor);
  cursor: pointer;
  font-size: 16px;
}

.rightTextInfoLizaRegister {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.contentSubInfo>h3 {
  width: 50%;
}

.contentSubInfo>span {
  margin-top: 3px;
  margin-bottom: 20px;
  text-align: justify;
  width: 50%;
}

.contentSubInfo>b {
  color: var(--primaryColorHover);
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50%;
  text-align: center;
}

.contentSubInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contentSubInfo>img>responsive {
  width: 100%;
  height: auto;
}

.buttonSoluctionLiza.active {
  border: 2px solid var(--primaryColor);
  color: var(--primaryColor);
  cursor: pointer;
}

/* #schedule-a-demo {
  padding: 10rem;
  height: 100vh;
  background-color: var(--whiteColor);
  display: flex;
  align-items: center;
  justify-content: center;
} */

#schedule-a-demo {
  padding: 10rem;
  height: 100%;
  background-color: var(--secondaryColor);
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
}


/* .contentDemo {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  text-align: center;
} */

.contentDemo {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

form.formItemLeft {
  display: flex;
  flex-direction: row;
}

.formItemLeft>input {
  margin-top: 0.5rem;
  height: 35px;
  width: 50%;
}

form.formItemRight {
  display: flex;
  flex-direction: row;
}

.formItemRight>input {
  margin-top: 0.5rem;
  height: 35px;
  width: 50%;
}

input {
  text-indent: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.buttonForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.buttonForm>button {
  border: none;
  /* background-color: var(--primaryColor); */
  background-color: transparent;
  border: 2px solid var(--primaryColor);
  margin-top: 1rem;
  width: 112px;
  height: 43px;
  color: var(--primaryColor);
}

.buttonForm>button:hover {
  background-color: var(--primaryColorHover);
  transition: 0.5s;
  cursor: pointer;
  color: #ffffff;
}

#footer {
  width: 100%;
  background-color: var(--secondaryColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#footerInfo {
  padding: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  color: var(--whiteColor);
  text-align: center;
}

.footerCopyright>p {
  color: var(--whiteColor);
  font-size: 12px;
  margin-bottom: 12px;
}

.footerLinks {
  display: flex;
  flex-direction: column;
}

.footerLinks>a {
  color: var(--primaryColor);
  padding: 0.1rem;
}

.footerCompany {
  color: var(--whiteColor);
}

.footerCompany>p {
  padding: 0.1rem;
}


#register {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  justify-content: flex-start;
}

#valid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

#fof {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

#assembly {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

#contentButtonLiza {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
}

.menuLizaDevice>button {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: none;
}

.menuLizaDevice>button>i {
  color: var(--primaryColor)
}

.titlePartners>p {
  font-weight: bold;
  margin-bottom: 3px;
}

.titleSchedule {
  color: #fff;
  font-weight: bold;
  font-size: 38px;
}

p.subTitleSchedule {
  color: #fff;
}

.formItemLeft input {
  background-color: var(--secondaryColor);
  border: 1px solid #fff;
  color: #ffffff;
}

.formItemLeft ::placeholder {
  color: #ffffff;
}

.linkedin {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}

.linkedin>p {
  font-weight: bold;
  margin-bottom: 3px;
}

.partnersImgages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.partnersImgages .itemPartners {
  margin-left: 5px;
}


.itemFooterContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
}

/* #success {
  margin: 1rem auto;
  width: 50%;
  padding: 0.5rem;
  background-color: #27C35D;
  color: #fff;
  border-radius: 3px;
} */

#success {
  width: 30%;
  height: auto;
  padding: 1rem;
  background-color: #27C35D;
  color: #fff;
  border-radius: 3px;
  z-index: 999;
  position: fixed;
  right: 10px;
  top: 119px;
}

#danger {
  margin: 1rem auto;
  width: 50%;
  padding: 0.5rem;
  background-color: #cc5b5b;
  color: #fff;
  border-radius: 3px;
}



/* Media Query for Mobile Devices */
@media (max-width: 480px) {

  input {
    width: 100% !important;
  }

  .logoLiza {
    display: none;
  }

  .menuLiza {
    display: none;
  }

  #menuLizaDeviceItem {
    display: none;
  }

  #register {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    justify-content: flex-start;
  }

  #valid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    justify-content: flex-start;
  }

  #fof {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    justify-content: flex-start;
  }

  #assembly {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    justify-content: flex-start;
  }

  #soluctions {
    padding: 1rem;
    /* height: 100%; */
    /* width: 100%; */
  }

  .contentButtons>button {
    padding: 0.3rem;
  }

  .leftButtonsInfoLizaRegister>.title {
    margin-bottom: 3rem;
  }

  .leftButtonsInfoLizaRegister>button {
    width: 100%;
  }

  #footer {
    width: 100%;
    height: auto;
    background-color: var(--secondaryColor) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #footerInfo {
    display: grid;
    grid-template-columns: 1fr;
    align-items: end;
    color: var(--whiteColor);
    text-align: center;
    grid-gap: 1rem;
    font-size: 12px;
  }

  .itemFooterContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contentSubInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contentSubInfo>h3 {
    width: 100%;
  }

  .contentSubInfo>span {
    margin-top: 3px;
    margin-bottom: 20px;
    text-align: justify;
    width: 100%;
  }

  .contentSubInfo>img {
    display: none;
  }

  .contentLizaWLCards {
    display: flex;
    flex-direction: column;
  }

  /* liza new */
  .area-liza-fof {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    align-items: center;
    gap: 24px;
  }

  /* #menu-device.active {
      display: block;
      position: fixed;
      top: 70px;
      width: 100%;
      height: auto;
      transition-delay: 1s;
      transition-property: display;
  } */


}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {

  input {
    width: 100% !important;
  }

  .logoLiza {
    display: none;
  }

  .menuLiza {
    display: none;
  }

  #menuLizaDeviceItem {
    display: none;
  }

  #register {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    justify-content: flex-start;
  }

  #valid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    justify-content: flex-start;
  }

  #fof {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    justify-content: flex-start;
  }

  #assembly {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    justify-content: flex-start;
  }

  #soluctions {
    padding: 1rem;
    /* height: 100%; */
    /* width: 100%; */
  }

  .contentButtons>button {
    padding: 0.3rem;
  }

  .leftButtonsInfoLizaRegister>.title {
    margin-bottom: 3rem;
  }

  .leftButtonsInfoLizaRegister>button {
    width: 100%;
  }

  #footer {
    width: 100%;
    height: auto;
    background-color: var(--secondaryColor) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #footerInfo {
    display: grid;
    grid-template-columns: 1fr;
    align-items: end;
    color: var(--whiteColor);
    text-align: center;
    grid-gap: 1rem;
    font-size: 12px;
  }

  .itemFooterContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contentSubInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contentSubInfo>h3 {
    width: 100%;
  }

  .contentSubInfo>span {
    margin-top: 3px;
    margin-bottom: 20px;
    text-align: justify;
    width: 100%;
  }

  .contentSubInfo>img {
    display: none;
  }

  .contentLizaWLCards {
    display: flex;
    flex-direction: column;
  }

  /* liza new */
  button.button-schedule {
    width: 50%;
    /* font-size: 12px; */
  }

  .area-liza-fof {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    align-items: center;
    gap: 24px;
  }

  /* #menu-device.active {
      display: block;
      position: fixed;
      top: 70px;
      width: 100%;
      height: auto;
      transition-delay: 1s;
      transition-property: display;
  } */


}

.positionImage {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  input {
    width: 100% !important;
  }

  .logoLiza {
    display: none;
  }

  .menuLiza {
    display: none;
  }

  #menuLizaDeviceItem {
    display: none;
  }

  #register {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    justify-content: flex-start;
  }

  #valid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    justify-content: flex-start;
  }

  #fof {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    justify-content: flex-start;
  }

  #assembly {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    justify-content: flex-start;
  }

  #soluctions {
    padding: 1rem;
    /* height: 100%; */
    /* width: 100%; */
  }

  .contentButtons>button {
    padding: 0.3rem;
  }

  .leftButtonsInfoLizaRegister>.title {
    margin-bottom: 3rem;
  }

  .leftButtonsInfoLizaRegister>button {
    width: 100%;
  }

  #footer {
    width: 100%;
    height: auto;
    background-color: var(--secondaryColor) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #footerInfo {
    display: grid;
    grid-template-columns: 1fr;
    align-items: end;
    color: var(--whiteColor);
    text-align: center;
    grid-gap: 1rem;
    font-size: 12px;
  }

  .itemFooterContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contentSubInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contentSubInfo>h3 {
    width: 100%;
  }

  .contentSubInfo>span {
    margin-top: 3px;
    margin-bottom: 20px;
    text-align: justify;
    width: 100%;
  }

  .contentSubInfo>img {
    display: none;
  }

  .contentLizaWLCards {
    display: flex;
    flex-direction: column;
  }

  .area-liza-fof {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    align-items: center;
    gap: 24px;
  }

}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .logoLizaDevice {
    display: none;
  }

  .menuLizaDevice {
    display: none;
  }

  #menuLizaDeviceItem {
    display: none;
  }

  #footer {
    width: 100%;
    height: auto;
    background-color: var(--secondaryColor) !important;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #footerInfo {
    display: grid;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .contentLizaWLCards {
    display: flex;
    flex-direction: column;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .logoLizaDevice {
    display: none;
  }

  .menuLizaDevice {
    display: none;
  }

  #menuLizaDeviceItem {
    display: none;
  }

  /* #footer {
      width: 100%;
      height: auto;
      background-color: var(--secondaryColor) !important;
      font-size: 12px;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  } */

  #footerInfo {
    display: grid;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .contentLizaWLCards {
    display: flex;
    flex-direction: column;
  }
}

/* 

white label

*/

.contentLizaWL {
  width: 90%;
  margin: 0 auto;
  margin-top: 3%;
  margin-bottom: 5%;
}

.contentLizaWLCards {
  margin-top: 3%;
  display: grid;
  margin-bottom: 5%;
  grid-template-columns: 1fr 1fr 1fr;
}

h3.subTitleWL {
  color: #393C50;
  font-size: 28px;
  font-weight: bold;
}

h4.subTextWL {
  color: #393C50;
  font-size: 20px;
  font-weight: bold;
}

span.textDisabled {
  color: #9D9B9B;
  font-size: 16px;
}

.cardwl img {
  margin-top: 10px;
  margin-bottom: 20px;
}


/* Pitch */

.p-header {
  height: 100vh;
  width: 100%;
  background-image: url('../assets/img/header-background.png');
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.hilight-template {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.box-green {
  background-color: var(--primaryColor);
  width: 100%;
  height: auto;
  padding: 1%;
}

.box-blue {
  background-color: var(--secondaryColor);
  width: 100%;
  height: 200px;
  padding: 5%;
}

.hilight-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.hilight-icon span {
  font-size: 3rem;
}

.content-info {
  display: flex;
  flex-direction: column;
  padding: 5%;
  background: rgba(221, 221, 221, 0.15);
}

.content-info-video {
  padding: 5%;
  background-color: #fff;
}

.content-info-video h2 {
  margin-bottom: 5%;
}

.content-video {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  background-color: #fff;
}


.info-item {
  margin-bottom: 20px;
}

.info-item h3 {
  margin-top: 15px;
}

.info-item-about {
  margin-bottom: 10px;
}

.info-item h2 {
  color: var(--secondaryColor);
  font-weight: bold;
  font-size: x-large;
  margin-bottom: 7px;
}

.content-market {
  width: 100%;
  height: auto;
  background-color: #323646;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-type {
  color: var(--linkColor);
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pitch-links {
  line-height: 30px;
}

.pitch-links span {
  font-weight: bold;
}

/* media queries */

@media (min-width:320px) {

  .headerLizaWL span {
    /* margin-top: 2%; */
    font-size: 14px;
  }

  a.buttonLizaWL {
    margin-top: 16px;
    font-size: 14px;
  }

  .contentLizaWLCards {
    margin-top: 3%;
    display: grid;
    margin-bottom: 5%;
    grid-template-columns: 1fr;
    gap: 50px;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }

  /* liza new */
  button.button-schedule {
    width: 80%;
    font-size: 14px;
  }

  /* #menu-device.active {
      display: block;
      position: fixed;
      top: 70px;
      width: 100%;
      height: auto;
      transition-delay: 1s;
      transition-property: display;
  } */
}

@media (min-width:481px) {
  .contentLizaWLCards {
    margin-top: 3%;
    display: grid;
    margin-bottom: 5%;
    grid-template-columns: 1fr;
    gap: 50px;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }

  /* liza new */
  button.button-schedule {
    width: 80%;
  }

  /* #menu-device.active {
      display: block;
      position: fixed;
      top: 70px;
      width: 100%;
      height: auto;
      transition-delay: 1s;
      transition-property: display;
  } */
}

@media (min-width:641px) {
  .contentLizaWLCards {
    margin-top: 3%;
    display: grid;
    margin-bottom: 5%;
    grid-template-columns: 1fr;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }

  /* liza new */
  button.button-schedule {
    width: 60%;
  }

  /* #menu-device.active {
      display: block;
      position: fixed;
      top: 70px;
      width: 100%;
      height: auto;
      transition-delay: 1s;
      transition-property: display;
  } */
}

@media (min-width:961px) {
  .contentLizaWLCards {
    margin-top: 3%;
    display: grid;
    margin-bottom: 5%;
    grid-template-columns: 1fr 1fr 1fr;
  }

  /* liza new */
  button.button-schedule {
    width: 40%;
  }

  /* #menu-device.active {
      display: block;
      position: fixed;
      top: 70px;
      width: 100%;
      height: auto;
      transition-delay: 1s;
      transition-property: display;
  } */
}

@media (min-width:1025px) {
  .contentLizaWLCards {
    margin-top: 3%;
    display: grid;
    margin-bottom: 5%;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .institional-video iframe {
    width: 520px;
    height: 250px;
  }

  /* goliza new */
  button.button-schedule {
    width: 40%;
  }
}

@media (min-width:1281px) {

  .contentLizaWLCards {
    margin-top: 3%;
    display: grid;
    margin-bottom: 5%;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .institional-video iframe {
    width: 560px;
    height: 280px;
  }

  /* goliza new */
  button.button-schedule {
    width: 40%;
  }
}

.institional-video {
  margin-top: 1%;
  border: 5px solid #ffffff;
}

@media only screen and (max-width: 600px) {

  body {
    font-size: 100%;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }

  .content-product-button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .tab-line {
    display: none;
  }

  .header-info {
    & h1 {
      font-size: 3rem;
    }

    & span {
      font-size: 2rem;
    }
  }

  .content-steps {
    & button {
      margin-bottom: 3rem;
    }

    .content-wrapper-other-solutions {
      margin-bottom: 4rem;
    }
  }

  .content-phrase {
    & p {
      font-size: 1.2rem;
    }
  }

}


.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
}

.modal-content h2 {
  border: 3px solid var(--primaryColor);
  width: 50%;
  margin: 0 auto;
  text-align: center;
  padding: 1%;
  color: var(--secondaryColor)
}

.modal-content h2 span {
  color: var(--primaryColor);
  font-weight: bold;
}

.content-liza-graphic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 40px;
}


.modal-actions a {
  background: var(--primaryColor);
  padding: 2%;
  color: white;
}

.modal-actions a.modal-button-outlined {
  background: transparent;
  padding: 2%;
  color: var(--primaryColor);
  cursor: pointer;
}


@media (min-width:320px) {

  .create-account {
    font-size: 16px;
  }

  .area-products p {
    font-size: 16px;
  }

  .area-products ul {
    font-size: 14px;
  }

  .fof-content span {
    font-size: 14px;
  }

  .button-schedule {
    font-size: 12px;
  }

  .logoLizaPitch {
    margin-left: 20px;
  }

  .content-video {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #fff;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }

  /* whitelabel */

  /* liza new */
  /* #menu-device.active {
      display: block;
      position: fixed;
      top: 70px;
      width: 100%;
      height: auto;
      transition-delay: 1s;
      transition-property: display;
  } */
}

@media (min-width:481px) {
  .logoLizaPitch {
    margin-left: 20px;
  }

  .content-video {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #fff;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }

  /* liza new */
  .phrase h3 {
    font-size: 3vh;
  }
}

@media (min-width:641px) {
  .logoLizaPitch {
    margin-left: 20px;
  }

  .content-video {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #fff;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }

  /* liza new */
  /* #menu-device.active {
      display: block;
      position: fixed;
      top: 70px;
      width: 100%;
      height: auto;
      transition-delay: 1s;
      transition-property: display;
  } */
}

@media (min-width:961px) {
  .logoLizaPitch {
    margin-left: 130px;
  }

  .content-video {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    background-color: #fff;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
  }
}

@media (min-width:1025px) {
  .logoLizaPitch {
    margin-left: 130px;
  }

  .content-video {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    background-color: #fff;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
  }
}

@media (min-width:1281px) {
  .logoLizaPitch {
    margin-left: 130px;
  }

  .content-video {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    background-color: #fff;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 30%;
  }
}


.video-item {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* goLiza new */

@media only screen and (max-width: 600px) {

  .menu-header {
    display: none;
  }

  .menu-header-display {
    display: block;
    z-index: 9999;
  }

  .menu-icon {
    color: #ffffff;
  }

  /* new */

  .header-info h2 {
    width: 100%;
    font-size: 3vh;
  }

  .header-info button {
    width: 50%;
  }

  .phrase h3 {
    font-size: 3vh;
  }

  .content-phrase {
    height: fit-content;
    width: 90%;
    margin: 0 auto;
  }

  .products {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .bodyCustomer {
    display: flex;
    flex-direction: column;
  }

  .bodyPartners {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 600px) {
  .menu-header {
    display: none;
  }

  .menu-header-display {
    display: block;
    z-index: 9999;
  }

  .menu-icon {
    color: #ffffff;
  }

  /* new */

  /* #menu-device.active {
      display: block;
      position: fixed;
      top: 70px;
      width: 100%;
      height: auto;
      transition-delay: 1s;
      transition-property: display;
  } */

  .header-description h2 {
    font-size: 32px;
    color: #ffffff;
    font-weight: 100;
    width: 50%;
  }

  .header-description span {
    /* font-weight: bold; */
  }

  .header-info {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  .header-info>button {
    background-color: var(--primaryColor);
    border: none;
    color: #ffffff;
    height: 40px;
    width: 25%;
  }

  .content-phrase {
    height: fit-content;
    width: 90%;
    margin: 0 auto;
  }

  .content-phrase>h3 {
    /* font-size: 36px; */
    /* font-weight: 100; */
    /* color: var(--secondaryColor); */
  }

  .content-phrase>h3>span {
    /* font-weight: bold; */
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .menu-header {
    display: none;
  }

  .menu-header-display {
    display: block;
    z-index: 9999;
  }

  .menu-icon {
    color: #ffffff;
  }

  /* new */

  /* #menu-device.active {
      display: block;
      position: fixed;
      top: 70px;
      width: 100%;
      height: auto;
      transition-delay: 1s;
      transition-property: display;
  } */

  .header-description h2 {
    font-size: 32px;
    color: #ffffff;
    font-weight: 100;
    width: 50%;
  }

  .header-description span {
    /* font-weight: bold; */
  }

  .header-info {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  .header-info>button {
    background-color: var(--primaryColor);
    border: none;
    color: #ffffff;
    height: 40px;
    width: 25%;
  }

  .content-phrase {
    height: fit-content;
    width: 90%;
    margin: 0 auto;
  }

  .content-phrase>h3 {
    /* font-size: 36px;
    font-weight: 100;
    color: var(--secondaryColor); */
  }

  .content-phrase>h3>span {
    /* font-weight: bold; */
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .menu-header {
    display: block;
  }

  .menu-header-display {
    display: none;
    z-index: 9999;
  }


  /* new */

  /* #menu-device.active {
      display: block;
      position: fixed;
      top: 70px;
      width: 100%;
      height: auto;
      transition-delay: 1s;
      transition-property: display;
  } */

  .header-description h2 {
    font-size: 32px;
    color: #ffffff;
    font-weight: 100;
    width: 50%;
  }

  .header-description span {
    /* font-weight: bold; */
  }

  .header-info {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  .header-info>button {
    background-color: var(--primaryColor);
    border: none;
    color: #ffffff;
    height: 40px;
    width: 25%;
  }

  .content-phrase {
    height: fit-content;
    width: 90%;
    margin: 0 auto;
  }

  .content-phrase>h3 {
    /* font-size: 36px; */
    /* font-weight: 100; */
    /* color: var(--secondaryColor); */
  }

  .content-phrase>h3>span {
    /* font-weight: bold; */
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .menu-header {
    display: block;
  }

  .menu-header-display {
    display: none;
    z-index: 9999;
  }

  /* new */
  .header-description h2 {
    font-size: 32px;
    color: #ffffff;
    font-weight: 100;
    width: 50%;
  }

  .header-description span {
    /* font-weight: bold; */
  }

  .header-info {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  .header-info>button {
    background-color: var(--primaryColor);
    border: none;
    color: #ffffff;
    height: 40px;
    width: 25%;
  }

  .content-phrase {
    height: fit-content;
    width: 90%;
    margin: 0 auto;
  }

  .content-phrase>h3 {
    /* font-size: 36px; */
    /* font-weight: 100; */
    /* color: var(--secondaryColor); */
  }

  .content-phrase>h3>span {
    /* font-weight: bold; */
  }

  button.button-schedule {
    width: 25%;
    font-size: 16px;
  }
}


/*  */

.lz-header {
  height: 30vh;
  width: 100%;
  background-image: url('../assets/img/bg-cvm.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lz-header .lz-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 12%;
}

.colorPrimary {
  color: var(--primaryColor) !important;
}

.colorSecondary {
  color: var(--secondaryColor) !important;
}

.italic {
  font-style: italic;
}

.lz-header h2 {
  color: #FFFFFF;
  margin-left: 3rem;
  width: 37vw;
}

.lz-content {
  width: 100%;
  height: 100%;
  padding: 3rem;
}

.lz-content-two {
  width: 100%;
  height: 100%;
  padding: 3rem;
  margin-top: -3rem;
}

.lz-content span {
  color: var(--secondaryColor);
}

.lz-content .content-description {
  margin-top: 3rem;
}

.lz-content .content-sub {
  margin-top: 3rem;
}

.lz-content .content-chapter {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.lz-content .content-chapter>span {
  margin-top: 1rem;
}

.content-article {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.content-article span {
  margin-top: 0.5rem;
}

.lz-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 150px;
  justify-content: space-around;
  align-items: center;
  background-color: #43B65E;
  color: #ffffff;
}

a.lz-create-account {
  padding: 1rem;
  background: var(--secondaryColor);
  color: #ffffff;
  font-size: 16px;
}

a.lz-create-account-primary {
  padding: 0.8rem;
  background: var(--primaryColor);
  color: #ffffff;
}

.lz-content-header {
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.lz-content-header .lz-item-header-caption {
  width: 50%;
  text-align: justify;
}

.lz-content-header .lz-item-header-text {
  width: 50%;
  font-size: 28px;
  text-align: left;
}

.lz-card {
  background: #ffffff;
  height: max-content;
  width: 100%;
  padding: 2%;
  margin-top: 20px;
}

.lz-card-ad {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2.5rem;
  align-items: center;
  justify-content: space-around;
  background: var(--primaryColor);
  height: max-content;
  width: 80%;
  padding: 4%;
  color: #ffffff;
  margin-top: 20px;
  background-image: url('../assets/img/header-background.png');
  background-position: center;
}

.lz-ad-text span {
  color: #ffffff;
  font-weight: 100;
  font-size: 18px;
}

.lz-content-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  font-size: 36px;
  text-align: left;
  height: max-content;
  padding: 10%;
  background: var(--secondaryColor);
  color: #ffffff;
}

span.lz-spacing-text {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
  .lz-content-header {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .lz-content-header>.lz-item-header-text {
    width: 100%;
  }

  .lz-content-header>.lz-item-header-caption {
    width: 100%;
  }

  .lz-footer {
    text-align: center;
    height: 250px;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
  }

  .lz-card-ad {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  .lz-ad-button {
    font-size: 14px;
  }

}

@media only screen and (max-width: 1343px) {
  .lz-footer {
    text-align: center;
    height: 250px;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
  }
}

@media only screen and (max-width: 1348px) {
  .lz-card-ad {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    padding: 3rem;
  }
}











/* Slider */

.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  height: 150px;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: auto;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}



/* OTHER SOLUTIONS */
.content-other-solutions {
  background-color: #ffffff;
  width: 97%;
  margin: 0 auto;

  & .content-other-solutions-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 33vh;
    width: 100%;

    & .other-solution-item {
      margin-top: 3rem;
      width: 50%;

      & h1 {
        font-weight: bold;
      }

      button {
        height: 270px;
        border: 2px solid var(--secondaryColor);
        padding: 4rem;
        width: 100%;
        background-color: #ffffff;
      }

      button.active {
        height: 270px;
        color: #ffffff;
        background-color: var(--secondaryColor);
        padding: 4rem;
        width: 100%;
        border: 1px solid var(--secondaryColor);
      }
    }

    & .other-solution-item-liza {
      margin-top: 3rem;
      width: 50%;

      & h1 {
        font-weight: bold;
      }

      button {
        height: 270px;
        border: 2px solid var(--secondaryColor);
        padding: 4rem;
        width: 100%;
        background-color: #ffffff;
      }

      button.active {
        height: 270px;
        color: #ffffff;
        background-color: var(--secondaryColor);
        padding: 4rem;
        width: 100%;
        border: 1px solid var(--secondaryColor);
      }
    }
  }

  & .content-sub-items {
    & .sub-item {
      button {
        background: #ffffff;
        padding: 1.5rem;
        font-weight: bold
      }
    }

    button.active {
      color: #ffffff;
      background-color: var(--secondaryColor);
      border: 2px solid var(--secondaryColor);
    }
  }
}

@media only screen and (max-width: 664px) {
  .content-other-solutions {

    & .content-other-solutions-buttons {
      & .other-solution-item {
        width: 33%;
        height: 74px;

        & h1 {
          font-size: 2rem;
          font-weight: bold;
        }

        button {
          padding: 0;
        }

        button.active {
          padding: 0;
        }
      }

      & .other-solution-item-liza {
        width: 50%;
        height: 74px;

        & h1 {
          font-size: 2rem;
          font-weight: bold;
        }

        button {
          padding: 0;
        }

        button.active {}
      }
    }

    & .content-sub-items {
      margin-top: 7rem;

      & .sub-item {
        button {
          height: 74px;
        }
      }

      button.active {}
    }
  }
}